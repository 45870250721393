import React, { useState, useEffect } from 'react';
import logo from '../images/c4t.png';
import '../style.css';
import secondLogo from '../images/c4t-logo.png';
import { NavLink, Link} from 'react-router-dom';
import linkedin from '../images/linkedin.png';
import instagram from '../images/instagram.png';





const Navbar = () => {

const [navbar, setNavbar] = useState(false);

const changeBackground = () => {
    if(window.scrollY >= 80){
        setNavbar(true);
    }else{
        setNavbar(false);
    }
}

window.addEventListener('scroll', changeBackground);



    return (
  <>
         
              
                <nav className= {`navbar navbar-expand-lg fixed-top ${navbar ? 'header_fixed' : 'page_header'}`} >
                    <div className="container-fluid body-font">
            

                        <Link className="navbar-brand" to="/"><img src={secondLogo} alt="c4t logo"/></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <i class="fa fa-bars" aria-hidden="true"></i>
                        </button>
                     
                     
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className={navbar ? 'navbar-nav navbarApply full-width' : 'navbar-nav full-width'} >
                            <li className="nav-item">
                                <NavLink className="nav-link" aria-current="page" to="/research">Research</NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" aria-current="page" to="/teacher-training"> Teacher Training </NavLink>
                            </li>

                        
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/products"> Infrastructure & EdTech </NavLink>
                            </li>
                            <li class="nav-item dropdown">
                                <NavLink class="nav-link" to="/assess/assessments"  role="button" >
                                    Assessment services
                                </NavLink>
                            
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link black-text" to="/safety">Safety4kids</NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink className="nav-link" to="/people" role="button">
                                    Our People
                                </NavLink>
                            </li>
                        
                            
                        </ul>

                        <div className="socialIcon text-center">
                            <a href="https://www.linkedin.com/company/code-4teen/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>
                            <a href="https://www.instagram.com/code_4teen_labs/"><i className="fa fa-instagram black-text fat-text"></i></a>
                        </div>
                        </div>
                    </div>

                </nav>

        </>
     );
}
 
export default Navbar;