import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import landingBanner from '../images/safety.jpg';
import $ from 'jquery/dist/jquery.js';
import Navigation from './navigation.js';
import pedagogy from '../images/pedagogy.jpg';
import vle from '../images/vle.png'
import atc1 from '../images/Atc-1.jpg';
import atc2 from '../images/Atc-2.jpg';
import atc3 from '../images/Atc-3.jpg';
import safetyBannerMob from '../images/safety-banner-mob.jpg';
import Footer from '../components/footer';
import Carousel from 'react-bootstrap/Carousel'

class safety extends Component { 


  constructor(props){
       super(props)
       this.state={

       }


       this.slideToWork = this.slideToWork.bind(this)


     }


componentDidMount(){ 

  window.scrollTo(0, 0)

}




slideToWork(){ 

  $('html,body').animate({
      scrollTop: $(".testimonials").offset().top},
      1000);

}

render(){


	return(

<div className="c4t-black-bg"> 

   <Navigation />

     <div className="row container-fluid standard-margin-top pOff mOff">
          <div className="col-12 top-banner standard-margin-top  safety-banner top-banner-dsk pOff"> 

            </div>
            <div className="col-12 top-banner standard-margin-top top-banner-mob pOff"> 
<img class="img-fluid" src={vle} />
</div>

            <div className="col-12 c4t-black-bg text-center pOff">
    <div className="col-12 col-md-8 mx-auto pOff standard-padding-rl-mobile"> 
      <h3 className="body-font white-text standard-margin-top">Embed Computer Languages into Foundational Literacy & Numeracy</h3> 

     <p className="body-font white-text thin-font standard-margin-top standard-margin-bottom"> 
     Andrew Teaches Coding Interactive textbooks is an educational resource following a language-based approach towards helping elementary schooler learn how to read, write and communicate ideas using computer languages to advance technology literacy. 

     <br/>
     <br/>
     Developed off our research, Our textbooks embed automated pedagogy & data analytics(formative assessments) into delivering vocabulary, grammar and functions focused content for HTML/CSS. Followed by progression to a skills-based phase focused on ensuring students creatively apply their new computer language skills towards professional coding. 
     </p> 

     </div>
    </div>
       </div> 


<div className="container c4t-black-bg">
   


{/*-------end of row ---------*/}


<div className="row sectional-margin-top c4t-black-bg text-center">

<h3 className="body-font white-text standard-margin-top full-width">Best Selling Titles</h3> 

<p className='body-font white-text'>
Andrew Teaches Coding Interactive textbooks has sold over 3000 copies till date with supply contracts with elementary schools extending till as far as 2026. We hereby deem our resource as the leading textbook for
extending literacy in classrooms beyond english language to include computer languages in a relatable and measurable format.
</p>


<div className="col-12" id="accordion">
  <div className="card col-12 full-width c4t-black-bg c4t-white-border">
    <div className="card-header text-center" id="headingOne">
      <h5 className="mb-0">
        <button className="btn btn-link text-center" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
   
       <figure>
       <img class="img-fluid bookcover" src={atc1} />
       <figcaption className='yellow-text'> HTML And The Web </figcaption>
       <Link To="#" className="btn c4t-btn black-text body-font yellow-text thin-font full-width">Explore</Link>

       </figure>

        </button>
      </h5>
    </div>

    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div className="card-body">
      <h4 className='header-font yellow-text'> Title Description </h4>
        <p className="white-text">
        Students gain a practical understanding of the basics of web publishing and are able to transcribe any body of text creatively
      into web formats using the HTML language
        </p>

        <h4 className='header-font yellow-text'> Learning Outcome </h4>
      <p className='header-font white-text'>
      Students are expected to take thier understand of web publishing to transform their expression of creative writing.
      Students gain the ability to explore use of media formats outside text and digital medium outside of the traditional word
      editor to express the storylines, while reinforcing their understanding of the key learning concepts 
      </p>

      <h4 className='header-font yellow-text'> Cross Curriculum Reinforcements </h4>
      <ul>
      <li className='header-font white-text'>
         Creative writing
        </li>
      </ul>
    
   
 </div>
    </div>
  </div>
  <div className="card col-12 full-width  c4t-black-bg c4t-white-border">
    <div className="card-header text-center" id="headingTwo">
      <h5 className="mb-0">
        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
       <figure>
       <img class="img-fluid bookcover" src={atc2}/>
       <figcaption className='yellow-text'> Interface Design & Analysis 1 </figcaption>
       <Link To="#" className="btn c4t-btn black-text body-font thin-font yellow-text full-width">Explore</Link>
       </figure>
     
        </button>
      </h5>
    </div>
    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div className="card-body">
      <h4 className='header-font yellow-text'> Title Description </h4>
      <p className="white-text">
      Students progress towards implementation of styles to give their interfaces a more professional outlook is
      gained.The learn grammatical rules associated with implementing CSS language in combination with HTML to express and communicate ideas fluently in web format. All activities are made possible by
      combining HTML language from with selective portions of CSS.
      </p>

      <h4 className='header-font yellow-text'> Learning Outcome </h4>
      <p className='header-font white-text'>
      Core Concepts of Desktop publishing, under the new ICT structure is reinforced as students come incontact with text
styling charactertisitcs such as Font types,Styles, alignmnent and color. These concepts are then implemented in an
alternative manner for web publishing under the Andrew teaches coding learning scheme. 
      </p>

      <h4 className='header-font yellow-text'> Cross Curriculum Reinforcements </h4>
      <ul>
      <li className='header-font white-text'>
         Creative writing
        </li>
      </ul>
</div>
    </div>
  </div>
  <div className="card col-12 full-width  c4t-black-bg c4t-white-border">
    <div className="card-header text-center" id="headingThree">
      <h5 className="mb-0">
        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        <figure>
       <img class="img-fluid bookcover" src={atc3}/>
       <figcaption className="yellow-text"> Interface Design & Analysis 2  </figcaption>
       <Link To="#" className="btn c4t-btn black-text body-font thin-font yellow-text full-width">Explore</Link>
       </figure>
        </button>
      </h5>
    </div>
    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div className="card-body">
      <h4 className='header-font yellow-text'> Title Description </h4>
      <p className="white-text">
      Students progress to mastering abstract analysis of web interfaces. Learners are able to breakdown the grid requirements of web interfaces and define the structural solutions to implementing interfaces. All activities are made possible by combining HTML language from with selective portions of CSS.
    </p>

    <h4 className='header-font yellow-text'> Learning Outcome </h4>
      <p className='header-font white-text'>
      Students understanding of word problem
analysis and used of divisions, percentages
are reinforced by the term B
curriculum. Students will be required to
use this knowledge as the foundation for
learning key concepts around recreating
various web formats in a professional
manner.
      </p>

      <h4 className='header-font yellow-text'> Cross Curriculum Reinforcements </h4>
      <ul>
      <li className='header-font white-text'>
         Mathematics
        </li>
      <li className='header-font white-text'>
         Creative writing
        </li>
      </ul>

      
 </div>
    </div>
  </div>
</div>


<div className="col-12 col-md-6 sectional-margin-top-visual" id="safetyCurriculum">

     

</div>
</div> 


{/*-------end of row ---------*/}







{/*-------end of row ---------*/}

   


</div>

<Footer/>

 </div>
  
  )
  
}

}


export default safety;