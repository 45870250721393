import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import landingBanner from '../images/home-banner.jpg';
import $ from '../../node_modules/jquery/dist/jquery.js';
import ssseb from '../images/Logo.jpg';
import fountain from '../images/fs.jpg';
import greensprings from '../images/Greensprings-1.jpg';
import beesknees from '../images/Beesknees.png';
import Navigation from './navigation.js';
import pedagogy from '../images/pedagogy.jpg';
import guardian from '../images/guardian.png';
import thisday from '../images/thisdau.png';
import businessday from '../images/business-day.png';
import curriculum from '../images/curriculum.jpg';
import vle from '../images/vle.png'
import mobilelab from '../images/mobile-lab-front-page.jpg';
import mobilelab1 from '../images/mobile-lab.jpeg';
import safety from '../images/safety-front-page.jpg';
import alarm from '../images/alarm-safety.jpg'
import alarmMob from '../images/alarm-front-banner-mobile.jpg';
import pedagogyMob from '../images/pedagogy-front-banner-mobile.jpg';
import fariah from '../images/fariah.jpeg';
import bgs from '../images/bgs.jpeg';
import Footer from '../components/footer';
import Carousel from 'react-bootstrap/Carousel'
import Carousel2 from 'react-bootstrap/Carousel'
import Carousel3 from 'react-bootstrap/Carousel'


class home extends Component { 


  constructor(props){
       super(props)
       this.state={

       }


          this.slideToWork = this.slideToWork.bind(this)

     }


componentDidMount(){ 

  window.scrollTo(0, 0)


}


slideToWork(){ 

    $('html,body').animate({
        scrollTop: $(".work-section").offset().top},
        1000);

}



render(){


  return(

<div className=""> 
<Navigation />

  <div className="container-fluid pOff mOff">
  <div className="col-12 pOff"> 


  <Carousel fade id="homepageslidedsk" className="carousel slide  top-banner-dsk d-none d-sm-block" >
  <Carousel.Item>
    <img
    
      src={landingBanner}
      alt="First slide"
      width='100%' 
    />
    <Carousel.Caption className="text-start">
      <h2 className="head-font">Teach computer language <br/> as a subject</h2>
      <p>Empower your school and ICT team to own the learning process 100% <br/>
      by integrating our Scheme of work, Infrastructure and Continous development plan </p>
      <Link  onClick={this.slideToWork} className="btn c4t-btn white-text body-font thin-font col-4">See our work</Link>

    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
  
      src={alarm}
      alt="Second slide"
      width='100%'
    />

    <Carousel.Caption className="text-start">
      <h2 className="body-font black-text">Don't Wait For the <br/> alarms to go off...</h2>
      <p className="body-font black-text">Sign up your school for our free online safety training for your educators today!</p>
      <Link To="/safety"  className="btn c4t-btn white-text body-font thin-font col-4"> Sign up </Link>

    </Carousel.Caption>
  </Carousel.Item>
  
</Carousel>


<Carousel fade id="homepageslidedsk" className="carousel slide d-block d-sm-none" >
  <Carousel.Item>
    <img
    
      src={pedagogyMob}
      alt="First slide"
      width='100%' 
    />
    <Carousel.Caption>
    <h4 className="head-font">Teach computer language as a subject</h4>
      <h6> Integrate an evidence-led Scheme of work, Infrastructure and Continous development plan </h6>
      <Link To="#" onClick={this.slideToWork} className="btn c4t-btn white-text body-font thin-font col-9 mx-auto">Request a pilot</Link>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
  
      src={alarmMob}
      alt="Second slide"
      width='100%'
    />

    <Carousel.Caption>
    <h4 className="body-font white-text">Don't wait for the <br/> alarms to go off...</h4>
      <h6 className="body-font white-text">Sign up your school for our free online safety training for your educators!</h6>
      <Link To="/safety"  className="btn c4t-btn white-text body-font thin-font col-9 mx-auto"> Sign up </Link>

    </Carousel.Caption>
  </Carousel.Item>

</Carousel>



  </div>
  
  </div> 
  <div className="row full-width text-center black-bg mOff">
    <div className="col-12 col-md-8 mx-auto standard-margin-top standard-margin-bottom standard-padding-rl-mobile">
     
     <h5 className="body-font white-text thin-font standard-margin-top standard-margin-bottom"> 
        Code14 LABS is committed to aiding the complete integration of computer science into the 
        academic ecosystem by empowering teachers with the tools & practices they need to succeed in embedding computer langauges into elementary & secondary curriculums.
     </h5> 

     </div>
    </div> 

  <div className="container standard-margin-top">


   

    <div className="row">
      
      <div className="col-12 col-md-6 sectional-margin-top-visual">
      
       <div className="row">
           <div className="col-12 col-md-5 standard-shadow standard-margin-bottom pOff" id="pedagogy">
           <div className="c4t-green-bg text-center"> 
             <h4 className="body-font fat-font white-text"> 
                Pedagogy
            </h4> 
           </div>
   
         </div>
         <div className="col-12 col-md-6" >

         <div className="row visual-padding-1">
           <div className="col-12 standard-shadow pOff" id="curriculum">

             <div className="c4t-blue-bg text-center"> 
            <h4 className="body-font fat-font white-text"> 
                Curriculum
            </h4> 
           </div>
   
           </div>
         </div>

            <div className="row visual-padding-2">
           <div className="col-12 standard-shadow pOff" id="collaboration">

          <div className="c4t-grey-bg text-center"> 

          <h4 className="body-font fat-font white-text"> 
                Collaboration
            </h4> 
             
           </div>
   
           </div>
         </div>

         </div>

       
       </div>



      </div>


      <div className="col-12 col-md-6 sectional-margin-top">
       <h2 className="body-font thin-font mobile-header-text">
         Welcome 
       </h2>  

         <h2 className="body-font fat-font mobile-header-text">
         <span className="thin-font"> to </span> 
         Code14 LABS
       </h2>
       <div className="c4t-blue-bg hor-bar-blue">


       </div> 

       <div className="c4t-yellow-bg hor-bar-yellow">


       </div> 

      <p className="body-font thin-font"> 
       We are a research and development company empowering teachers to improve learning outcomes through provision of continuous training, evidence-based educational content and
       technology inclusion in pedagogy, collaboration and curriculum delivery.
       </p>

    <Link To="#" onClick={this.slideToWork} className="btn c4t-btn black-text body-font thin-font full-width">See our work</Link>

      </div>



    </div> 



<div className="sectional-margin-top work-section"> 


    <div className="col-12 pOff">
       <h2 className="body-font thin-font mobile-header-text">
         A look 
       </h2>  

         <h2 className="body-font thin-font mobile-header-text">
         inside <span className="body-font fat-font"> our labs </span>
       </h2>

    
       <div className="c4t-blue-bg hor-bar-blue">


       </div> 

       <div className="c4t-yellow-bg hor-bar-yellow">


       </div> 

      <p className="body-font thin-font"> 
       We operate under two inter-related divisions in pursuance of <b>our mission to thread technology literacy sustainably into the fabric of basic education.</b>
      </p>

      </div>

     <div className="col-12 standard-margin-top"> 

     <div className="d-flex align-items-start">
  <div className="nav flex-column nav-pills me-5 d-none d-sm-block" id="v-pills-tab" role="tablist" aria-orientation="">
    <button className="nav-link body-font fat-text c4t-grey service-pill active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">   Research  </button>
    <button className="nav-link body-font fat-text c4t-grey service-pill" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"> Products </button>
  </div>

  <div className="nav flex-column nav-pills me-5 d-block d-sm-none col-12 pOff" id="v-pills-tab" role="tablist" aria-orientation="">
    <button className="nav-link body-font fat-text c4t-grey service-pill active col-12" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home-mob" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">   Research  </button>
    <button className="nav-link body-font fat-text c4t-grey service-pill col-12" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile-mob" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"> Products </button>
  </div>
  <div className="tab-content d-none d-sm-block" id="v-pills-tabContent">
    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
    <div className="row">
    <div className="col-12">
    <h6 className="body-font thin-font">
     Subject
    </h6> 
    <h3 className="body-font thin-font">
     Computer language as a progressive subject
    </h3>
    </div>
    <div className="col-12">

      <iframe width="100%" height="515" src="https://www.youtube.com/embed/VdYofNMTaCE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div> 
    <div className="col-12 col-md-6 standard- margin-top"> 
    <h4 className="body-font fat-font">
     Scope of Research
    </h4> 
    <p className="body-font thin-font">
      For 5 years, our research focus has been the development of a linear syllabus to aid the sustainable integration and delivery of computer languages 
      into the ICT/computing curriculum's as an at scale solution to technology literacy. We use empirical evidence for making key decisions
      such as curriculum entry level, approach to pedagogy, learning pace etc
    </p> 

    </div>

     <div className="col-12 col-md-6 standard-margin-top text-center"> 
     <h3 className="body-font thin-font">
     Education Partners
    </h3>
    <Carousel2 slide >
  <Carousel2.Item>
    <img  
      src={ssseb}
      alt="First slide"
      width='40%' 
    />
  </Carousel2.Item>
  <Carousel2.Item>
    <img
      src={greensprings}
      alt="Second slide"
      width='40%' 
    />
  </Carousel2.Item>
  <Carousel2.Item>
    <img
      src={beesknees}
      alt="Third slide"
      width='40%' 
    />
  </Carousel2.Item>

  <Carousel2.Item>
    <img
      src={fountain}
      alt="Third slide"
      width='40%' 
    />
  </Carousel2.Item>

  <Carousel2.Item>
    <img
      src={fariah}
      alt="Third slide"
      width='40%' 
    />
  </Carousel2.Item>

  <Carousel2.Item>
    <img
      src={bgs}
      alt="Third slide"
      width='40%' 
    />
  </Carousel2.Item>
</Carousel2>


      
    </div>

    <div className="col-12">
     <Link to="/research" className="btn c4t-btn black-text body-font thin-font full-width">Learn more </Link>
     </div>

    </div>


    </div>
    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">

     <div className="row"> 
     <div className="col-12">
    <h6 className="body-font fat-font">
     Featured Product
    </h6> 
    <h3 className="body-font thin-font">
     Connected Book API
    </h3>
    </div>
       <div className="col-12">

         <img src={vle} width="100%"/>

       </div>

       <div className="col-12 col-md-6 standard-margin-top"> 
       <h4 className="body-font fat-font">
    About Connected Books
    </h4> 
   
   <p className="body-font thin-font">
   Create an end-end digital content workflow for your learners & Educators using the Connected Books API. Empower your students with fully interactive versions of your native classroom content across all subjects. 
   </p> 

   </div>
   <div className="col-12 col-md-6 standard-margin-top text-center"> 
   <h4 className="body-font fat-font">
      Used by 
    </h4>

    <Carousel3 slide >
  <Carousel3.Item>
    <img  
      src={ssseb}
      alt="First slide"
      width='40%' 
    />
  </Carousel3.Item>
  <Carousel3.Item>
    <img
      src={greensprings}
      alt="Second slide"
      width='40%' 
    />
  </Carousel3.Item>
  <Carousel3.Item>
    <img
      src={beesknees}
      alt="Third slide"
      width='40%' 
    />
  </Carousel3.Item>

  <Carousel3.Item>
    <img
      src={fountain}
      alt="Third slide"
      width='40%' 
    />
  </Carousel3.Item>

  <Carousel3.Item>
    <img
      src={fariah}
      alt="Third slide"
      width='40%'
    />
  </Carousel3.Item>

  <Carousel3.Item>
    <img
      src={bgs}
      alt="Third slide"
      width='40%' 
    />
  </Carousel3.Item>
</Carousel3>
   </div>
     
     <div className="col-12">
     <Link to="/products" className="btn c4t-btn black-text body-font thin-font full-width">Learn more </Link>
     </div>
     </div>
  
    </div>
      </div>
</div>
            
     </div>


    <div className="row">
      <div class="tab-content d-block d-sm-none " id="v-pills-tabContent">
      <div class="tab-pane fade show active" id="v-pills-home-mob" role="tabpanel" aria-labelledby="v-pills-home-tab"><div class="row"><div class="col-12">
      <h6 class="body-font thin-font">Subject</h6>
      <h3 class="body-font thin-font">Computer language as a progressive subject</h3>
      </div>
      <div class="col-12">
       <iframe width="100%" height="515" src="https://www.youtube.com/embed/VdYofNMTaCE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div> 
    <div className="col-12 col-md-6 standard- margin-top"> 
    <h4 className="body-font fat-font">
     Scope of Research
    </h4> 
    <p className="body-font thin-font">
    For 5 years, our research focus has been the development of a linear syllabus to aid the sustainable integration and delivery of computer languages 
      into the ICT/computing curriculum's as an at scale solution to technology literacy. We use empirical evidence for making key decisions
      such as curriculum entry level, approach to pedagogy, learning pace etc
    </p> 

    </div>

     <div className="col-12 col-md-6 standard-margin-top text-center"> 
     <h3 className="body-font thin-font">
     Education Partners
    </h3>
     <div id="researchPartners" className="carousel slide" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item ">
    <img src={ssseb} className=""  width="40%" alt="..." /> 
    </div>
    <div className="carousel-item active">
    <img src={greensprings} className=""  width="40%" alt="..." />
    </div>
    <div className="carousel-item">
    <img src={beesknees} className=""  width="40%" alt="..." />
    </div>
    <div className="carousel-item">
    <img src={fountain} className=""  width="40%" alt="..." />
    </div>
    </div>
    </div>

      
    </div>
    <div className="col-12">
     <Link to="/research" className="btn c4t-btn black-text body-font thin-font full-width">Learn more </Link>
     </div>
      </div>
      </div>

      <div class="tab-pane fade" id="v-pills-profile-mob" role="tabpanel" aria-labelledby="v-pills-profile-tab">
        

      <div className="row"> 
     <div className="col-12">
    <h6 className="body-font fat-font">
     Featured Product
    </h6> 
    <h3 className="body-font thin-font">
     Andrew Teaches Coding 
    </h3>
    </div>
       <div className="col-12">

         <img src={vle} width="100%"/>

       </div>

       <div className="col-12 col-md-6 standard-margin-top"> 
       <h4 className="body-font fat-font">
    About our interactive textbooks
    </h4> 
   
   <p className="body-font thin-font">
   Andrew Teaches Coding contains linear curriculum content for teaching Grade 4, 5 and 6 computer language across HTML, CSS and JavaScript in a compounded format. Our books are embedded with data analytics capabilities to drive the formative and summative assesments needs of educators looking for data-led improvements of learning outcomes. </p>
   </div>
   <div className="col-12 col-md-6 standard-margin-top text-center"> 
   <h4 className="body-font fat-font">
      Used by 
    </h4>
     <div id="booksPartners" className="carousel slide" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
    <img src={greensprings} className=""  width="40%" alt="..." />
    </div>
    <div className="carousel-item">
    <img src={beesknees} className=""  width="40%" alt="..." />
    </div>
    <div className="carousel-item">
    <img src={fountain} className=""  width="40%" alt="..." />
    </div>
    </div>
    </div>
   </div>
     
     <div className="col-12">
     <Link to="/products" className="btn c4t-btn black-text body-font thin-font full-width">Learn more </Link>
     </div>
     </div>
        
        
        </div>
        
        
        </div>
      

      <div className="col-12 col-md-6 sectional-margin-top">
       <h2 className="body-font thin-font mobile-header-text">
         Empowering educators
       </h2>  

         <h2 className="body-font fat-font mobile-header-text">
         through online safety
       </h2>
       <div className="c4t-blue-bg hor-bar-blue">


       </div> 

       <div className="c4t-yellow-bg hor-bar-yellow">


       </div> 

      <p className="body-font thin-font fat-text"> 

we are reaching thousands
of students by working with
education providers to 
deliver online safety workshops
      </p>

          <Link to="/safety" className="btn c4t-btn black-text body-font thin-font full-width">Register your school</Link>

      </div>

<div className="col-12 col-md-6 sectional-margin-top-visual">
      
       <div className="row">
           
         <div className="col-12 col-md-6" >

         <div className="row visual-padding-1">
           <div className="col-12 standard-shadow pOff" id="chrisland">
   <div className="c4t-blue-bg text-center"> 
            <h4 className="body-font fat-font white-text"> 
                We've empowered
            </h4> 
           </div>
           
   
           </div>
         </div>

            <div className="row visual-padding-2 standard-margin-bottom">
           <div className="col-12 standard-shadow pOff" id="beesknees">

          <div className="c4t-grey-bg text-center"> 

       
             
           </div>
   
           </div>
         </div>
         </div>
<div className="col-12 col-md-5 standard-shadow pOff" id="greensprings">
         
   
         </div>
       
       </div>



      </div>


    </div> 

{/*-------Start of Slider-----*/}
<div className="row mt-5">
    <div className="col-12">
    <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={mobilelab1} class="d-block img-fluid" alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div> 
    </div>
</div>





{/*----start of media section----*/}

<div className="row mt-5">
    <div className="col-12 text-center">
        <h2>Media</h2>
        <hr/>
    </div>
    <div className="col-12 col-md-6">
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/SNaarMPbxPM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <div className="col-12 col-md-6">
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/f6mENk3W_Ic" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
</div>

{/*----end of media section----*/}


   


<div className="row sectional-margin-top text-center">

<div className="col-12 standard-margin-bottom ">
   <h3 className="body-font thin-font">
   As seen <span className="fat-font"> in the news </span>
   </h3>               
</div>

<div className="col-12 col-md-4 text-center">

<div className="card standard-shadow mx-auto">
  <img src={thisday} className="card-img-top" alt="..." />
  <div className="card-body c4t-blue-bg">
    <h5 className="card-title white-text">Headline</h5>
    <p className="card-text white-text">Edfin MFB partners Code14 LABS to promote digital literacy on ILD 2023 </p>
    <a href="https://www.thisdaylive.com/index.php/2020/02/01/code-4teen-labs-to-partner-fountain-school-moral-ville-academy/" className="btn btn-primary c4t-btn">Read full Story</a>
  </div>
</div>

</div>

<div className="col-12 col-md-4 text-center">


<div className="card standard-shadow mx-auto">
  <img src={businessday} className="card-img-top" alt="..." />
  <div className="card-body c4t-blue-bg">
    <h5 className="card-title white-text">Headline</h5>
    <p className="card-text white-text">Lagos partners firm to integrate coding in primary schools’ curriculum</p>
    <a href="https://businessday.ng/education/article/lagos-partners-firm-to-integrate-coding-in-primary-schools-curriculum/" className="btn btn-primary c4t-btn">Read full Story</a>
  </div>
</div>

</div>

<div className="col-12 col-md-4">

<div className="card standard-shadow mx-auto">
  <img src={guardian} className="card-img-top" alt="..." />
  <div className="card-body c4t-blue-bg">
    <h5 className="card-title white-text">Headline</h5>
    <p className="card-text white-text">
Research lab partners Lagos schools on computer literacy integration</p>
    <a href="https://guardian.ng/news/research-lab-partners-lagos-schools-on-computer-literacy/" className="btn btn-primary c4t-btn">Read full Story</a>
  </div>
</div>

</div>





</div>

</div>




   </div> 

   <Footer/>
</div>

    )
}

}


export default home