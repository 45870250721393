import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import landingBanner from '../images/safety.jpg';
import $ from 'jquery/dist/jquery.js';
import Navigation from './navigation.js';
import pedagogy from '../images/pedagogy.jpg';
import chrisland from '../images/chrisland.png';
import greensprings from '../images/Greensprings-1.jpg';
import fountain from '../images/fs.jpg';
import beesknees from '../images/Beesknees.png';
import kidscourt from '../images/kid.png';
import rainbow from '../images/rainbow.png';
import safetyBannerMob from '../images/safety-banner-mob.jpg';
import Footer from '../components/footer';
import Carousel from 'react-bootstrap/Carousel'

class safety extends Component { 


  constructor(props){
       super(props)
       this.state={

       }


       this.slideToWork = this.slideToWork.bind(this)


     }


componentDidMount(){ 

  window.scrollTo(0, 0)


}




slideToWork(){ 

  $('html,body').animate({
      scrollTop: $(".testimonials").offset().top},
      1000);

}

render(){


	return(

<div className=""> 

   <Navigation />

     <div className="row container-fluid   pOff mOff">
          <div className="col-12 top-banner safety-banner-1 top-banner-dsk pOff"> 

            </div>
            <div className="col-12 top-banner top-banner-mob pOff"> 
<img class="img-fluid" src={safetyBannerMob} />
</div>

            <div className="col-12 c4t-green-bg text-center pOff">
    <div className="col-12 col-md-8 mx-auto pOff standard-padding-rl-mobile"> 
      <h3 className="body-font white-text standard-margin-top">Importance of Online Safety for Kids </h3> 

     <h5 className="body-font white-text thin-font standard-margin-top standard-margin-bottom"> 
        Online safety is a critical now more than ever with the integrationof technology into education 
        at minority level and code 4TEEN is continously supporting  education providers on their journey by providing
        training and sensitization to address some of the risks involved 
     </h5> 

     </div>
    </div>
       </div> 


<div className="container ">
   


{/*-------end of row ---------*/}


<div className="row sectional-margin-top">


<div className="col-12 col-md-6 sectional-margin-top">
 <h2 className="body-font thin-font mobile-header-text">
   Facilitated using 
 </h2>  

   <h2 className="body-font fat-font mobile-header-text">
   
    trusted curriculum
 </h2>
 <div className="c4t-blue-bg hor-bar-blue">


 </div> 

 <div className="c4t-yellow-bg hor-bar-yellow">


 </div> 

<p className="body-font thin-font fat-text"> 
 We are accelerating the spread of internet
etiquette by organising free safety workshops
and using globally accredited curriculum 

</p>
<Link onClick={this.slideToWork} className="btn c4t-btn black-text body-font thin-font full-width"> Read Testimonials </Link>



</div>


<div className="col-12 col-md-6 sectional-margin-top-visual" id="safetyCurriculum">

     

</div>
</div> 


{/*-------end of row ---------*/}





    <div className="row text-center sectional-margin-top-visual testimonials">
    <div className="col-12 sectional-margin-top"> 
      <h2 className="body-font c4t-grey standard-margin-bottom mobile-header-text"> What Leading Brands have to say </h2> 

<Carousel indicators={false}>
  
<Carousel.Item>
  <figure> 
    <p className="body-font">
  "It was beneficial to the participants. Thank you for facilitating the training."
  </p>
  <figcaption> 
      <h6 className="body-font fat-font black-text"> Dr. Ollie Oduwole </h6>
      <h6 className="body-font fat-font black-text"> Director Of Studies </h6>
      <h6 className="body-font fat-font black-text"> Pampers Group of Schools </h6>
  </figcaption>
    </figure>
    <Carousel.Caption className="black-text">

     
    </Carousel.Caption>
  </Carousel.Item>
  
  <Carousel.Item>
    <figure> 
      <p className="body-font"> 
      "Awesome and helpful."
      </p>
    <figcaption> 
        <h6 className="body-font  fat-font black-text"> Isaac Joseph </h6>
        <h6 className="body-font fat-font black-text"> Head of ICT </h6>
        <h6 className="body-font fat-font black-text"> Chrisland Schools </h6>
    </figcaption>
      </figure>

  </Carousel.Item>
  <Carousel.Item>

    <figure>
        <p className="body-font thin-font"> 
         "For the teachers, it was informative.""
         </p>
         <figcaption> 
         <h6 className="body-font fat-font black-text"> Mrs Olufunke Philips </h6>
         <h6 className="body-font fat-font black-text"> Head of School </h6>
         <h6 className="body-font fat-font black-text"> Kids Court School </h6>
         </figcaption>
      </figure>
  
  </Carousel.Item>
  <Carousel.Item>
  <figure> 
  <p className="body-font thin-font"> 
         "It was a good experience. With the manual, teachers can train their pupils independently. Thank you for the opportunity."
      </p>

      <figcaption> 
      <h6 className="body-font fat-font black-text"> Mrs Eberechi Chukwuocha </h6>
      <h6 className="body-font fat-font black-text"> Head, Administration </h6>
      <h6 className="body-font fat-font black-text"> Blooming Greens School </h6>
      </figcaption>
    </figure>
  
  </Carousel.Item>
</Carousel>




     </div>

     <div className="col-12 col-md-2">
       <img src={chrisland} className="card-img-top img-fluid" alt="..." />
     </div>

      <div className="col-12 col-md-2">
       <img src={greensprings} className="card-img-top img-fluid" alt="..." />
     </div>

      <div className="col-12 col-md-2">
       <img src={fountain} className="card-img-top img-fluid" alt="..." />
     </div>

       <div className="col-12 col-md-2">
       <img src={beesknees} className="card-img-top img-fluid" alt="..." />
     </div>

      <div className="col-12 col-md-2">
       <img src={kidscourt} className="card-img-top img-fluid" alt="..." />
     </div>

       <div className="col-12 col-md-2">
       <img src={rainbow} className="card-img-top" alt="..." />
     </div>


    </div>

{/*-------end of row ---------*/}

    <div className="row">
      
      <div className="col-12 col-md-6 sectional-margin-top-visual">
      
       <div className="row">
           <div className="col-12 col-md-5 standard-shadow standard-margin-bottom pOff" id="safety-1">
           <div className="c4t-green-bg text-center"> 
             <h4 className="body-font fat-font white-text"> 
                At home
            </h4> 
           </div>
   
         </div>
         <div className="col-12 col-md-6" >

         <div className="row visual-padding-1">
           <div className="col-12 standard-shadow pOff" id="safety-2">

             <div className="c4t-blue-bg text-center"> 
            <h4 className="body-font fat-font white-text"> 
                In school
            </h4> 
           </div>
   
           </div>
         </div>

            <div className="row visual-padding-2">
           <div className="col-12 standard-shadow pOff" id="safety-3">

          <div className="c4t-grey-bg text-center"> 

          <h4 className="body-font fat-font white-text"> 
                During play
            </h4> 
             
           </div>
   
           </div>
         </div>

         </div>

       
       </div>



      </div>


      <div className="col-12 col-md-6 sectional-margin-top">
       <h2 className="body-font thin-font mobile-header-text">
         Learner safety
       </h2>  

         <h2 className="body-font fat-font mobile-header-text">
         <span className="thin-font"> should </span> 
          always come first
       </h2>
       <div className="c4t-blue-bg hor-bar-blue">


       </div> 

       <div className="c4t-yellow-bg hor-bar-yellow">


       </div> 

      <p className="body-font thin-font fat-text"> 
       Rapid uptake of online education has created a need for 
students & educators to be sensitized on safe useage of the internet. 

      </p>
    {/*---Modal----*/}

    <Link  className="btn c4t-btn black-text body-font thin-font full-width" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"> Schedule a free workshop </Link>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">  Schedule a free workshop today.   </h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <label for="recipient-name" className="col-form-label black-text">School Name</label>
            <input type="text" className="form-control" placeholder="School Name" id="recipient-name"/>
          </div>
          <div class="mb-3">
            <label for="recipient-name" className="col-form-label black-text">Contact Person</label>
            <input type="text" className="form-control" placeholder="Contact Person" id="recipient-name"/>
          </div>
          <div class="mb-3">
            <label for="recipient-name" className="col-form-label black-text">Designation</label>
            <input type="text" className="form-control" placeholder="Designation" id="recipient-name"/>
          </div>
          <div class="mb-3">
            <label for="message-text" className="col-form-label black-text">Number of teachers to be trained</label>
            <textarea className="form-control" placeholder="10" id="message-text"></textarea>
          </div>
          <div class="mb-3">
            <label for="recipient-name" className="col-form-label black-text">Preferred Date for training </label>
            <input type="text" className="form-control" placeholder="10th June 2027" id="recipient-name"/>
          </div>
          <div class="mb-3">
            <label for="recipient-name" className="col-form-label black-text">Preferred Time </label>
            <input type="text" className="form-control" placeholder="10am" id="recipient-name"/>
          </div>
          <div class="mb-3">
            <label for="recipient-name" className="col-form-label black-text"> Email address </label>
            <input type="text" className="form-control" placeholder="jason@school.com" id="recipient-name"/>
          </div>
          <div class="mb-3">
            <label for="recipient-name" className="col-form-label black-text"> Direct Line </label>
            <input type="number" className="form-control" placeholder="+2348105010073" id="recipient-name"/>
          </div>
        </form>
      </div>
      <div class="col-12 standard-margin-bottom">
        <button type="button" className="btn c4t-btn full-width"> Request for Safety Training </button>
      </div>
    </div>
  </div>
</div>


      </div>



    </div> 





{/*-------end of row ---------*/}

   


</div>

<Footer/>

 </div>
  
  )
  
}

}


export default safety;