import React, { Component, useState, useEffect  } from 'react';
import {Route, Link } from "react-router-dom"
import { useForm } from "react-hook-form";
import landingBanner from '../images/bas.jpg';
import landingBannerMob from '../images/basemob.jpg';
import fluency from '../images/fluency.png';

import create from '../images/create.png';
import gaps from '../images/gap.png';
import analysis from '../images/analysis.png';
import teach from '../images/teach.png'; 
import jQuery from 'jquery';
import Navigation from './navigation.js';
import { render } from '@testing-library/react';
import Carousel from 'react-bootstrap/Carousel'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import fountain from '../images/fs.jpg';
import greensprings from '../images/Greensprings-1.jpg';
import city from '../images/CPS.png';
import fariah from '../images/fariah.jpeg';
import nisl from '../images/NISL.jpeg';
import lagoon from '../images/lagoon.png';
import grandmates from '../images/gmates.png';
import priory from '../images/priory.png';
import Modal2 from 'react-bootstrap/Modal';
import secondLogo from '../images/c4t.png';
import heritage from '../images/heritage.png';
import windsor from '../images/windsor.png';
import queensland from '../images/queensland.jpeg';
import axios from 'axios';
import {read, utils} from 'xlsx';
import Card from 'react-bootstrap/Card';
import content from '../images/content.png';
import idea from '../images/idea.png';
import syntax from '../images/syntax.png';
import literacy from '../images/literacy.jpg';
import employ from '../images/employ.jpg';
import Footer from '../components/footer';
import cli from './cli.js';
import clf from './clf.js';
import clim from './clim.js';



 const Exam = () =>{ 

   const [forms, setForms] = useState(false);
   const [schoolLogo, setSchoolLogo] = useState("");
   const [selectedFile, setSelectedFile] = useState(null);
   const [xcelRows, setXcelRows] = useState([]);
   
   window.scrollTo(0, 0)
 
   const requiredFields = ["firstname", "lastname", "class"];

   useEffect(()=> {
       
   }, [])


   function slideToWork(){ 

      jQuery('html,body').animate({
          scrollTop: jQuery(".literate").offset().top},
          1000);
  
  }




    async function showForm1Back(e){ 
      e.preventDefault()
       jQuery('#form2').hide('fast', ()=> { 
          
         jQuery('#form1').show('fast')
       })
    }



    async function showForm2Back(e){ 
      e.preventDefault()
       jQuery('#form3').hide('fast', ()=> { 
          
         jQuery('#form2').show('fast')
       })
    }

    async function showForm3(e){ 
      e.preventDefault()
       jQuery('#form2').hide('fast', ()=> { 
          
         jQuery('#form3').show('fast')
       })
    }


    async function showForm3Back(e){ 
      e.preventDefault()
       jQuery('#form4').hide('fast', ()=> { 
          
         jQuery('#form3').show('fast')
       })
    }

    async function showForm4(e){ 
      e.preventDefault()
       jQuery('#form3').hide('fast', ()=> { 
          
         jQuery('#form4').show('fast')
       })
    }


    async function showForm4Back(e){ 
      e.preventDefault()
       jQuery('#form5').hide('fast', ()=> { 
          
         jQuery('#form4').show('fast')
       })
    }

    async function showForm5(e){ 
      e.preventDefault()
       jQuery('#form4').hide('fast', ()=> { 
          
         jQuery('#form5').show('fast')
       })
    }

    async function showForm5Back(e){ 
      e.preventDefault()
       jQuery('#form6').hide('fast', ()=> { 
          
         jQuery('#form5').show('fast')
       })
    }

    async function showForm6(e){ 
      e.preventDefault()
       jQuery('#form5').hide('fast', ()=> { 
          
         jQuery('#form6').show('fast')
       })
    }



    let { register, handleSubmit, watch, formState: { errors } } = useForm()
    let onSubmit = async function (data){
              console.log(xcelRows)
              const finalData ={ 
                initial: data,
                students: xcelRows
              }
        console.log(finalData)
      
      
 
          axios
      .post("https://c4t1.herokuapp.com/createschoolviaapi", finalData , {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        alert(res);
      })
      .catch((err) => {
        console.log(err);
      });
         
    
    
    };
   let excelRows

    const readUploadFile = (e) => {
      e.preventDefault();

     
      if (e.target.files) {
        const file = e.target.files[0];
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = utils.sheet_to_json(worksheet);
          excelRows = json

          setXcelRows(excelRows)
          
          validateData()
          console.log(json);
        };
        reader.readAsArrayBuffer(file);
 
   }

 
    };

    let completeRegistrationButton = document.getElementById('registration')


    const validateData = async () => {
      try {
      
        const firstItemKeys = excelRows;
        console.log(firstItemKeys)
  
        let requiredValidation = false;
  
        if (firstItemKeys.length) {
          firstItemKeys.forEach((element) => {
            console.log(element)
            if (!element.firstname || !element.lastname|| !element.class) {
              requiredValidation = true;
            }
          });
        }
  
        if (requiredValidation) {
          alert("Required fields " + JSON.stringify(requiredFields));
         
          jQuery(completeRegistrationButton).removeClass("fufil")
          jQuery(completeRegistrationButton).addClass("disabled")
          return;
         
        }

        jQuery(completeRegistrationButton).addClass("fufil")
        jQuery(completeRegistrationButton).removeClass("disabled")

       alert('candidate data valid')
       
        ``  
       return 
      } catch (error) {
        console.log("uploadData error: ", error);
      }
    };







    return(
<>
        <Navigation />

        <div className="row container-fluid   pOff mOff">
        <div className="col-12 col-md-6 c4t-red-bg standard-padding standard-padding-mobile"> 

<h3 className='body-font white-text standard-margin-top'> Literacy & Employability Assessments  </h3>
<h5 className="body-font white-text thin-font standard-margin-top standard-margin-bottom mob-body"> 
    Accurately measure & verify your students progress towards mastery in text-based coding. Our assessment data serves as an invaluable tool for understanding and responding to individual gaps of your learners, guaranteeing they can land their
    first technology role in a respectable company 
        </h5> 

<Link To="#" onClick={slideToWork} className="btn c4t-btn white-text body-font thin-font full-width standard-margin-bottom"> Schedule an assessment </Link>



</div>

<div className="col-12 col-md-6 pOff"> 
<img src={landingBanner} width="100%" />

</div>


             <div className="col-12 top-banner assessment-banner top-banner-dsk pOff "> 

             <div className="col-12 col-md-7 standard-padding-top-bottom blue-block-bg ">
<h6 className='body-font standard-margin-top white-text mOff'> Every educator knows... </h6>
<h3 className='body-font white-text'> An assessment in time, saves nine!  </h3>

<h5 className="white-text standard-margin-top standard-margin-bottom">
<b> CODE baseline assessments </b> provide an objective view of your students strengths and abilities in text-based coding. Each assessment highlights specific gaps in knowledge, helping you shape your schools approach towards lesson planning and teaching. Working together 
this drives mastery and eventual employability of students during & post k-12 education.
</h5>

<h6 className='body-font standard-margin-top standard-margin-bottom white-text mOff center-text-mobile'> Place your learners on the path to mastery & employability by repeating these steps </h6>
<div className="flex-container edu-hide">

          

<Card className="card1" >
 <Card.Body className="text-center">
 <img src={gaps} className="img-fluid standard-margin-bottom" width="40%" /> 
    <Card.Subtitle className="mb-2 text-muted"> Identify gaps </Card.Subtitle>
 </Card.Body>
  </Card>

  <Card className="card1" >
 <Card.Body className="text-center">
 <img src={analysis} className="img-fluid  standard-margin-bottom" width="40%" /> 
   
    <Card.Subtitle className="mb-2 text-muted"> Analyse results </Card.Subtitle>
 </Card.Body>
  </Card>

  <Card className="card1" >
 <Card.Body className="text-center">
 <img src={teach} className="img-fluid  standard-margin-bottom" width="40%" /> 
   
    <Card.Subtitle className="mb-2 text-muted"> Interventions </Card.Subtitle>
 </Card.Body>
  </Card>

  <Card className="card1" >
 <Card.Body className="text-center">
 <img src={teach} className="img-fluid  standard-margin-bottom" width="40%" /> 
    <Card.Subtitle className="mb-2 text-muted"> Re-testing </Card.Subtitle>
 </Card.Body>
  </Card>


  </div>


  <div className="row flex-container  d-block d-sm-none">

          

<Card className="card2 col-5" >
 <Card.Body className="text-center">
 <img src={gaps} className="img-fluid standard-margin-bottom" width="40%" /> 
    <Card.Subtitle className="mb-2 text-muted"> Identifing gaps </Card.Subtitle>
 </Card.Body>
  </Card>

  <Card className="card2 col-7" >
 <Card.Body className="text-center">
 <img src={analysis} className="img-fluid  standard-margin-bottom" width="40%" /> 
   
    <Card.Subtitle className="mb-2 text-muted"> Analysing results </Card.Subtitle>
 </Card.Body>
  </Card>

  <Card className="card2 col-9" >
 <Card.Body className="text-center">
 <img src={teach} className="img-fluid  standard-margin-bottom" width="40%" /> 
   
    <Card.Subtitle className="mb-2 text-muted"> Interventions </Card.Subtitle>
 </Card.Body>
  </Card>

  <Card className="card2 col-11" >
 <Card.Body className="text-center">
 <img src={teach} className="img-fluid  standard-margin-bottom" width="40%" /> 
    <Card.Subtitle className="mb-2 text-muted"> Re-testing </Card.Subtitle>
 </Card.Body>
  </Card>


  </div>



  <h6 className='body-font standard-margin-top white-text mOff standard-margin-bottom text-center literate'> Learn more about how assessment data will help you improve learning outcomes </h6>
  <a href="tel:+2348161682957"className="btn c4t-white-btn white-text body-font standard-margin-top thin-font full-width standard-margin-bottom"> Talk to our insights team</a>

</div>
     
               </div>
   
      

        <div className="col-12 literacyBg pOff"> 
        <div className="col-12 offset-md-5 col-md-7 standard-padding-top-bottom green-block-bg ">
           <h6 className='body-font standard-margin-top white-text mOff'> Path 1</h6>
           <h3 className='body-font white-text'>Measure Key Literacy Indicators  </h3>

           <h5 className="white-text standard-margin-top standard-margin-bottom">
              Becoming a computer science expert starts with understanding computer langauge. Schools use our literacy 
              assessment to confirm their students are on the right track towards mastery and employability from day zero :)  <br/><br/>

              Including a Computer Languge Literacy Certificate in high school/college applications will
              improve chances of gaining scholarships and admissions into schools home & abroad looking to recruit globally competitve talent.
          </h5>

          <h6 className='body-font standard-margin-top white-text mOff center-text-mobile'> Choose an assessment to learn more </h6>
          <div className="flex-container">

          
        <Link to="/assess/assessments/literacy/cli-pathway">

       
          <Card className="card1">
           <Card.Body className="text-center">
           <img src={syntax} className="img-fluid" width="40%" /> 
             <Card.Title>CLI-1 </Card.Title>
              <Card.Subtitle className="mb-2 text-muted"> Computer Language Identification Test </Card.Subtitle>
           </Card.Body>
            </Card>

            </Link>


            <Link to="/assess/assessments/literacy/clf-pathway">
            <Card className="card1 standard-margin-bottom">
           <Card.Body className="text-center">
           <img src={content} className="img-fluid" width="40%" /> 
             <Card.Title>CLF-1</Card.Title>
              <Card.Subtitle className="mb-2 text-muted"> Computer Language Fluency Test </Card.Subtitle>
           </Card.Body>
            </Card>

            </Link>

         


            </div>

            <h6 className='body-font standard-margin-top white-text mOff standard-margin-bottom text-center'> Not sure what literacy path to start from? </h6>
            <a  href="tel:+2348161682957"className="btn c4t-white-btn white-text body-font standard-margin-top thin-font full-width standard-margin-bottom"> Talk to an expert </a>

        </div>
        </div>

        <div className="col-12 employmentBg pOff"> 
        <div className="col-12 col-md-7 standard-padding-top-bottom yellow-block-bg ">
           <h6 className='body-font standard-margin-top white-text mOff'> Path 2 </h6>
           <h3 className='body-font white-text'>Verify Key Employability Indicators  </h3>

           <h5 className="white-text standard-margin-top standard-margin-bottom">
              For K-12 students, getting the first opportunity to apply coding skills you are learning at a real company can be challenging. Schools/Parents can support students by enrolling them for an employability assessment. <br/> <br/>Providing an Employability Verification Certificate to 
              possible employers will help your students achieve the credibility required to land their first technical role while still in school.
          </h5>
          <h6 className='body-font standard-margin-top white-text mOff center-text-mobile'> Choose an assessment to learn more </h6>
          <div className="flex-container">

          <Link to="/assess/assessments/employability/clim-pathway">
          <Card className="card1">
           <Card.Body className="text-center">
           <img src={idea} className="img-fluid" width="40%" /> 
             <Card.Title>CLI-2 </Card.Title>
              <Card.Subtitle className="mb-2 text-muted"> Computer Language Implementation Test </Card.Subtitle>
           </Card.Body>
            </Card>
            </Link>



            </div>

            <h6 className='body-font standard-margin-top white-text mOff standard-margin-bottom text-center'>  Not sure if you qualify for the employability assessment? </h6>
            <a  href="tel:+2348161682957"className="btn c4t-white-btn white-text body-font standard-margin-top thin-font full-width standard-margin-bottom"> Talk to an expert </a>

        </div>

        </div>

     

        <div className="col-12 text-center pOff standard-margin-bottom standard-margin-top">

        <div className="col-12 col-md-7 mx-auto standard-padding-top-bottom text-center"> 
            <h5 className=" mOff">
                  Trusted names in education use our assessment tools in class to track progress towards mastery 
           </h5>
        </div>


       
        <Splide className="standard-margin-bottom" options={{
         perPage: 3,
         type: 'loop',
         arrow: false,
         pagination: false,
         autoScroll: {
            speed: 0.5
          }, 
        }} extensions={{ AutoScroll }} aria-label="My Favorite Images">
        <SplideSlide>
           <img src={nisl} width="40%" alt="Image 1"/>
        </SplideSlide>
        <SplideSlide>
          <img src={greensprings} width="40%" alt="Image 2"/>
            </SplideSlide> 
            <SplideSlide>
           <img src={fountain} width="40%" alt="Image 1"/>
        </SplideSlide>
        <SplideSlide>
           <img src={lagoon} width="40%" alt="Image 1"/>
        </SplideSlide>
        <SplideSlide>
           <img src={grandmates} width="40%" alt="Image 1"/>
        </SplideSlide>
        <SplideSlide>
           <img src={priory} width="40%" alt="Image 1"/>
        </SplideSlide>
        <SplideSlide>
           <img src={fariah} width="40%" alt="Image 1"/>
        </SplideSlide>
        <SplideSlide>
           <img src={city} width="40%" alt="Image 1"/>
        </SplideSlide>
        <SplideSlide>
           <img src={queensland} width="40%" alt="Image 1"/>
        </SplideSlide>
        <SplideSlide>
           <img src={heritage} width="40%" alt="Image 1"/>
        </SplideSlide>
        <SplideSlide>
           <img src={windsor} width="40%" alt="Image 1"/>
        </SplideSlide>
        </Splide>

       <div className="col-12 col-md-8 mx-auto pOff standard-padding-rl-mobile"> 

       <h5 className="body-font sectional-margin-top"> Our assessment tool has been used to collect and process</h5> 
         <h1 className="body-font standard-margin-top monster-text"> 25,000+ </h1> 
         <h5 className="body-font standard-margin-top"> examination entries from </h5> 
         <h1 className="body-font standard-margin-top monster-text"> 1000+  </h1> 
         <h5 className="body-font standard-margin-top"> students </h5> 
        </div>
    </div>

       
    <div className="col-12 cpdBg pOff"> 
        <div className="col-12 offset-md-5 col-md-7 standard-padding-top-bottom grey-block-bg ">
           <h6 className='body-font standard-margin-top white-text mOff'> Give your students a headstart with  </h6>
           <h3 className='body-font white-text'> Educator Professional Development  </h3>

           <h5 className="white-text standard-margin-top standard-margin-bottom">
              Success in any endeavour takes intentional planning. Gain control over the preparation and end results of your students by 
              enrolling your educators under our professional development plan. Outcomes are tailored towards positioning teachers to 
              become certified professionals in the web development space.
          </h5>


                   <Link To="/teacher-training" className="btn c4t-white-btn white-text body-font standard-margin-top thin-font full-width standard-margin-bottom"> Learn more </Link>

        </div>
        </div>
 
          </div> 

          <Footer />

           
          </>
    )
          

}

export default Exam